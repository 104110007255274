import { PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Layout } from "../components/layout";
import { HomeNavigation } from "../components/navigation";
import { SectionWrapper, SectionRow } from "../components/section";

const StatutPage: React.FC<PageProps> = () => {

  return (
    <Layout title="Polityka prywatności" canonical="/polityka-prywatnosci/">
      <HomeNavigation />
      <Wrapper>
        <SectionWrapper>
          <SectionRow>
            <Headline>Polityka prywatności</Headline>
          </SectionRow>
        </SectionWrapper>
      </Wrapper>
      <SectionWrapper>
        <SectionRow>
          <RichTextWrapper>
            <p><span>Je&#347;li tu zagl&#261;dasz, to prawdopodobnie bezpiecze&#324;stwo Twoich danych jest dla Ciebie wa&#380;ne. Wspaniale! My&#347;limy podobnie i dlatego te&#380; stworzyli&#347;my polityk&#281; prywatno&#347;ci , kt&oacute;r&#261; zamieszczamy poni&#380;ej. </span>
            </p>
            <p><span>Fundacja Starsza Siostra jest organizacj&#261; pozarz&#261;dow&#261; o charakterze non-profit, kt&oacute;rej celem jest propagowanie psychoedukacji w&#347;r&oacute;d dzieci i doros&#322;ych oraz wspieranie aktywno&#347;ci dotycz&#261;cych profilaktyki zdrowia psychicznego.</span>
            </p>
            <p><span>W toku naszej dzia&#322;alno&#347;ci zbieramy i przetwarzamy dane osobowe os&oacute;b, z kt&oacute;rymi wsp&oacute;&#322;pracujemy, do kt&oacute;rych kierujemy nasze dzia&#322;ania, naszych darczy&#324;c&oacute;w i wolontariuszy. Robimy to po to, by skutecznie realizowa&#263; nasz&#261; misj&#281;.</span>
            </p>
            <p><span>Chcemy, by&#263; mia&#322;/mia&#322;a w pe&#322;ny wgl&#261;d w to, w jaki spos&oacute;b to robimy i da&#263; Ci w ten spos&oacute;b poczucie bezpiecze&#324;stwa, kiedy zechcesz wsp&oacute;&#322;pracowa&#263; z Fundacj&#261;</span>
            </p>
            <h3><span>RODO</span></h3>
            <p><span>Od 25 maja 2018 r. obowi&#261;zuje &nbsp;Rozporz&#261;dzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w zwi&#261;zku z przetwarzaniem danych osobowych i w sprawie swobodnego przep&#322;ywu takich danych oraz uchylenia dyrektywy 95/46/WE (okre&#347;lane popularnie jako &bdquo;RODO&rdquo;).</span>
            </p>
            <h3><span>Dane osobowe</span></h3>
            <p><span>Dane osobowe to, zgodnie z RODO, informacje o zidentyfikowanej lub mo&#380;liwej do zidentyfikowania osobie fizycznej. Takimi danymi s&#261; np.: imi&#281; i nazwisko, &nbsp;adres, telefon, identyfikator internetowy, stan zdrowia, stan cywilny, sytuacja ekonomiczna, sytuacja prawna.</span>
            </p>
            <h3><span>Administrator danych</span></h3>
            <p><span>Administratorem danych osobowych jest Fundacja Starsza Siostra, z siedzib&#261; przy ul. &#379;ywieckiej 50/15, 30-427 Krak&oacute;w, wpisana do Krajowego Rejestru S&#261;dowego pod numerem 0000946174.</span>
            </p>
            <p><span>Jak przetwarzamy dane?</span></p>
            <p><span>Dane osobowe przetwarzane s&#261; na podstawie zgody wyra&#380;anej przez U&#380;ytkownika (U&#380;ytkownicy Serwisu korzystaj&#261;cy z formularza kontaktowego), prawnie uzasadnionego interesu Fundacji (cele marketingowe) oraz w przypadkach, w kt&oacute;rych przepisy prawa upowa&#380;niaj&#261; Administratora do przetwarzania danych osobowych na podstawie przepis&oacute;w prawa lub w celu realizacji zawartej pomi&#281;dzy stronami umowy (np. umowa z wolontariuszami).</span>
            </p>
            <p><span>Twoje dane w Fundacji przetwarzane b&#281;d&#261; wy&#322;&#261;cznie w przypadku posiadania przez nas jednej z dopuszczonych przez RODO podstaw prawnych i wy&#322;&#261;cznie w celu dostosowanym do danej podstawy. Twoje dane przetwarzane b&#281;d&#261; do czasu istnienia podstawy do ich przetwarzania &ndash; czyli w przypadku udzielenia zgody do momentu jej cofni&#281;cia, ograniczenia lub innych dzia&#322;a&#324; z Twojej strony ograniczaj&#261;cych t&#281; zgod&#281;, w przypadku niezb&#281;dno&#347;ci danych do wykonania umowy &ndash; przez czas jej wykonywania, a w przypadku, gdy podstaw&#261; przetwarzania danych jest uzasadniony interes administratora &ndash; do czasu istnienia tego uzasadnionego interesu. </span>
            </p>
            <p><span>&nbsp;</span></p>
            <p><span>W Fundacji Starsza Siostra, zgodnie z RODO, podstaw&#281; prawn&#261; do przetwarzania danych stanowi&#261;:</span></p>
            <ol start={1}>
              <li><span>Twoja dobrowolna zgoda (np. na przesy&#322;anie newslettera). Korzystanie z wsparcia Fundacji nie jest mo&#380;liwe bez udzielenia zgody na przetwarzanie danych osobowych. Niezb&#281;dno&#347;&#263; przetwarzania do cel&oacute;w wynikaj&#261;cych z prawnie uzasadnionych interes&oacute;w realizowanych przez administratora czyli Fundacj&#281;. </span>
              </li>
              <li><span>Niezb&#281;dno&#347;&#263; przetwarzania do zawarcia lub wykonania umowy, kt&oacute;rej jeste&#347; stron&#261;. Mog&#261; to by&#263; np. umowy o wolontariacie, umowy i regulaminy podpisywane z pracownikami, wsp&oacute;&#322;pracownikami, wykonawcami us&#322;ug i klientami Fundacji, obs&#322;uga transakcji darowizn na rzecz Fundacji.</span>
              </li>
              <li><span>Niezb&#281;dno&#347;&#263; przetwarzania do cel&oacute;w wynikaj&#261;cych z prawnie uzasadnionych interes&oacute;w realizowanych przez administratora czyli Fundacj&#281; (np. konieczno&#347;&#263; archiwizacji danych i potrzeby wynikaj&#261;ce z prowadzenia strony internetowej, potrzeby ksi&#281;gowe czy rozliczenia i sprawozdania finansowe, podatkowe i inne)</span>
              </li>
              <li><span
              >Prawne zobowi&#261;zania, np.: przekazanie materia&#322;&oacute;w na &#380;&#261;danie uprawnionej instytucji pa&#324;stwowej.</span></li>
            </ol>
            <p><span>Fundacja przetwarza dane osobowe U&#380;ytkownik&oacute;w strony na podstawie swojego uzasadnionego interesu (art. 6 ust. 1 lit. f RODO), kt&oacute;ry stanowi:</span>
            </p>
            <ul>
              <li><span>zarz&#261;dzanie Stron&#261;,</span></li>
              <li><span>zapewnienie bezpiecze&#324;stwa Strony i U&#380;ytkownik&oacute;w,</span></li>
              <li><span>prowadzenie analiz statystycznych,</span></li>
              <li><span>spe&#322;nienie obowi&#261;zk&oacute;w prawnych wynikaj&#261;cych z RODO w zakresie realizacji praw i w zwi&#261;zku z tym archiwizowania &#380;&#261;da&#324; kierowanych do Fundacji lub informowania o zagro&#380;eniach dla prywatno&#347;ci.</span>
              </li>
              <li><span></span></li>
            </ul>
            <p><span>Dodatkowo po wyra&#380;eniu przez U&#380;ytkownika zgody Fundacja Starsza Siostra &nbsp;przetwarza&#263; b&#281;dzie dane osobowe U&#380;ytkownik&oacute;w na podstawie art. 6 ust. 1 lit. a RODO w celach:</span>
            </p>
            <ul>
              <li><span>odpowiadania na wiadomo&#347;ci o dowolnej tre&#347;ci przes&#322;ane przez U&#380;ytkownik&oacute;w za po&#347;rednictwem formularza kontaktowego,</span>
              </li>
              <li><span>marketingowych, w tym w celu informowania U&#380;ytkownik&oacute;w o dzia&#322;alno&#347;ci statutowej Fundacji, ofercie programowej, aktualno&#347;ciach, wydarzeniach (poprzez newsletter)</span>
              </li>
            </ul>
            <p><span></span></p>
            <p><span>Dane os&oacute;b dokonuj&#261;cych wp&#322;at darowizn</span></p>
            <p><span>Od os&oacute;b dokonuj&#261;cych wp&#322;at darowizn Fundacja Starsza Siostra zbiera podstawowe dane niezb&#281;dne do przetworzenia p&#322;atno&#347;ci: imi&#281;, nazwisko oraz adres e-mail lub &ndash; opcjonalnie &ndash; numer telefonu, a tak&#380;e dane dotycz&#261;ce p&#322;atno&#347;ci, takie jak kwota p&#322;atno&#347;ci oraz numer rachunku bankowego, z kt&oacute;rego zosta&#322;a dokonana p&#322;atno&#347;&#263;. Dane te udost&#281;pnione s&#261; przez podmioty obs&#322;uguj&#261;ce p&#322;atno&#347;ci. Podanie danych jest dobrowolne, jednak niezb&#281;dne do dokonania p&#322;atno&#347;ci i umo&#380;liwienia identyfikacji osoby dokonuj&#261;cej wp&#322;aty.</span>
            </p>
            <p><span>Dane os&oacute;b korzystaj&#261;cych z formularzy kontaktowych</span></p>
            <p><span>Od os&oacute;b korzystaj&#261;cych z formularzy kontaktowych za po&#347;rednictwem naszej strony internetowej zbieramy podstawowe dane umo&#380;liwiaj&#261;ce kontakt z U&#380;ytkownikiem: imi&#281;, nazwisko oraz adres e-mail lub &ndash; opcjonalnie &ndash; numer telefonu, a tak&#380;e wszelkie dane podane przez Pa&#324;stwa w formularzu kontaktowym. Podanie danych jest dobrowolne, jednak niezb&#281;dne do korzystania z formularza kontaktowego i umo&#380;liwienia Fundacji udzielenia odpowiedzi na zapytania za po&#347;rednictwem tych formularzy.</span>
            </p>
            <p><span></span></p>
            <p><span>Udost&#281;pniane danych</span></p>
            <p><span>Jedynie w niezb&#281;dnym zakresie Twoje dane mog&#261; by&#263; udost&#281;pniane</span><span>: instytucjom pa&#324;stwowym i samorz&#261;dowym uprawnionym do ich &#380;&#261;dania na podstawie przepis&oacute;w prawa, grantodawcom, zgodnie z &nbsp;zapisami um&oacute;w grantowych oraz podwykonawcom w celu realizacji us&#322;ugi. Przekazuj&#261;c dane wci&#261;&#380; odpowiadamy za ich przetwarzanie i dbamy, by ich udost&#281;pnianie odbywa&#322;o si&#281; zgodnie z obowi&#261;zuj&#261;cymi normami prawnymi. </span>
            </p>
            <p><span>Jakie mam prawa? &nbsp;</span></p>
            <p><span>Twoje uprawnienia dotycz&#261;ce Twoich danych i ich przetwarzania wynikaj&#261; z RODO. </span><span>W przypadku, gdy przetwarzamy dane osobowe dziecka, poni&#380;sze uprawnienia wykonuje rodzic lub opiekun prawny. W ka&#380;dej chwili mo&#380;esz wycofa&#263; uprzednio udzielon&#261; zgod&#281; na przetwarzanie danych osobowych</span>
            </p>
            <p><span>W ka&#380;dym czasie masz r&oacute;wnie&#380; prawo do:</span></p>
            <ul>
              <li><span>dost&#281;pu do swoich danych osobowych,</span></li>
              <li><span>sprostowania swoich danych osobowych,</span></li>
              <li><span>usuni&#281;cia swoich danych osobowych,</span></li>
              <li><span>ograniczenia przetwarzania swoich danych osobowych</span></li>
              <li><span>wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych,</span></li>
              <li><span>przenoszenia swoich danych osobowych.</span></li>
              <li><span>wniesienia skargi do organu nadzorczego </span></li>
            </ul>
            <p><span>Uprawnienia powy&#380;sze przys&#322;uguj&#261; Ci tak&#380;e w przypadku prawid&#322;owego przetwarzania danych przez administratora. Pami&#281;taj, &#380;e wycofanie zgody na przetwarzanie danych osobowych lub wniesienie sprzeciwu wobec przetwarzania, a tak&#380;e &#380;&#261;danie ograniczenia zakresu przetwarzania danych lub ich usuni&#281;cia nie wp&#322;ywa na zgodno&#347;&#263; z prawem przetwarzania dokonanego wcze&#347;niej.</span>
            </p>
            <p><span>Dzia&#322;amy z najwy&#380;sz&#261; staranno&#347;ci&#261;, by</span><span>&nbsp;zapewni&#263; bezpiecze&#324;stwo Twoich danych oraz realizujemy obowi&#261;zki wymagane przepisami prawa. Je&#347;li jednak uwa&#380;asz, &#380;e przetwarzamy Twoje dane osobowe niezgodnie z przepisami, przys&#322;uguje Ci prawo do wniesienia skargi do organu nadzorczego &ndash; Prezesa Urz&#281;du Ochrony Danych Osobowych.</span>
            </p>
            <h3><span>Chcesz o co&#347; zapyta&#263;?</span></h3>
            <p><span>Je&#347;li masz pytania lub uwagi do przetwarzania danych, prze&#347;lij je do nas na adres</span><span>&nbsp;e-mail: </span><span><a href="mailto:kontakt@starszasiostra.pl">kontakt@starszasiostra.pl</a></span><span>. </span></p>
            <p><span></span></p>
            <p><span>Pliki cookies</span></p>
            <ol start={1}>
              <li><span>Rozpoczynaj&#261;c korzystanie ze strony internetowej w domenie &lt;adres strony&gt; U&#380;ytkownik akceptuje poprzez aktywacj&#281; przycisku &bdquo;Zgadzam si&#281;&rdquo; umieszczenie przez stron&#281; internetow&#261; na urz&#261;dzeniu ko&#324;cowym U&#380;ytkownika (np. komputerze, laptopie, smartfonie, tablecie) danych informatycznych (plik&oacute;w cookies).</span>
              </li>
              <li><span>Pliki cookies (tzw. &bdquo;ciasteczka&rdquo;) to pliki tekstowe, kt&oacute;re przechowywane s&#261; w urz&#261;dzeniu ko&#324;cowym U&#380;ytkownika i przeznaczone s&#261; do korzystania ze stron internetowych. Cookies zazwyczaj zawieraj&#261; nazw&#281; strony internetowej, z kt&oacute;rej pochodz&#261;, czas przechowywania ich na urz&#261;dzeniu ko&#324;cowym oraz unikalny numer. Pliki cookies s&#261; przekazywane U&#380;ytkownikowi jako jedne z pierwszych, tu&#380; po wywo&#322;aniu Strony. Co wi&#281;cej, nie mo&#380;na tego kontrolowa&#263; z poziomu kodu programistycznego.</span>
              </li>
              <li><span>Podmiotem zamieszczaj&#261;cym na urz&#261;dzeniu ko&#324;cowym U&#380;ytkownika pliki cookies oraz uzyskuj&#261;cym do nich dost&#281;p jest Fundacja Starsza Siostra.</span>
              </li>
              <li><span>Pliki cookies wykorzystywane s&#261; przez Fundacj&#281; Starsza Siostra w celu:</span></li>
            </ol>
            <ol start={1}>
              <li><span>tworzenia statystyk, Google Analytics, w oparciu o polityk&#281; prywatno&#347;ci google http://google.pl/intl/pl/analytics/privacyoverview.html, kt&oacute;re pomagaj&#261; zrozumie&#263;, w jaki spos&oacute;b U&#380;ytkownicy korzystaj&#261; ze stron internetowych, co umo&#380;liwia ulepszanie ich struktury i zawarto&#347;ci;</span>
              </li>
            </ol>
            <ol start={5}>
              <li><span>dostosowania zawarto&#347;ci stron internetowych do preferencji U&#380;ytkownika oraz optymalizacji korzystania ze stron internetowych; w szczeg&oacute;lno&#347;ci pliki te pozwalaj&#261; rozpozna&#263; </span><span
              >urz&#261;dzenie U&#380;ytkownika </span><span>i odpowiednio wy&#347;wietli&#263; stron&#281; internetow&#261;, dostosowan&#261; do jego indywidualnych potrzeb;</span>
              </li>
              <li><span>utrzymania sesji U&#380;ytkownika;</span></li>
              <li><span>cookies u&#380;ywane s&#261; te&#380; przez strony internetowe, do kt&oacute;rych Strona si&#281; odwo&#322;uje np. multimedia czy serwisy spo&#322;eczno&#347;ciowe.</span>
              </li>
              <li><span>W ramach Strony stosowane s&#261; dwa zasadnicze rodzaje plik&oacute;w cookies: &bdquo;sesyjne&rdquo; (session cookies) oraz &bdquo;sta&#322;e&rdquo; (persistent cookies). Cookies &bdquo;sesyjne&rdquo; s&#261; plikami tymczasowymi, kt&oacute;re przechowywane s&#261; w urz&#261;dzeniu ko&#324;cowym U&#380;ytkownika do czasu wylogowania, opuszczenia strony internetowej lub wy&#322;&#261;czenia oprogramowania (przegl&#261;darki internetowej). &bdquo;Sta&#322;e&rdquo; pliki cookies przechowywane s&#261; w urz&#261;dzeniu ko&#324;cowym U&#380;ytkownika przez czas okre&#347;lony w parametrach plik&oacute;w cookies lub do czasu ich usuni&#281;cia przez U&#380;ytkownika.</span>
              </li>
              <li><span>W ramach Strony stosowane s&#261; nast&#281;puj&#261;ce rodzaje plik&oacute;w cookies:</span></li>
              <li><span>&bdquo;niezb&#281;dne&rdquo; pliki cookies, umo&#380;liwiaj&#261;ce korzystanie z us&#322;ug dost&#281;pnych w ramach Strony, np. uwierzytelniaj&#261;ce pliki cookies wykorzystywane do us&#322;ug wymagaj&#261;cych uwierzytelniania w ramach Strony;</span>
              </li>
            </ol>
            <ol start={1}>
              <li><span>pliki cookies s&#322;u&#380;&#261;ce do zapewnienia bezpiecze&#324;stwa, np. wykorzystywane do wykrywania nadu&#380;y&#263; w zakresie uwierzytelniania w ramach Strony;</span>
              </li>
              <li><span
              >&bdquo;wydajno&#347;ciowe&rdquo; pliki cookies, umo&#380;liwiaj&#261;ce zbieranie informacji o sposobie korzystania ze Strony.</span></li>
            </ol>
            <ol start={11}>
              <li><span>W wielu przypadkach oprogramowanie s&#322;u&#380;&#261;ce do przegl&#261;dania stron internetowych (przegl&#261;darka internetowa) domy&#347;lnie dopuszcza przechowywanie plik&oacute;w cookies w urz&#261;dzeniu ko&#324;cowym U&#380;ytkownika. U&#380;ytkownicy Strony mog&#261; dokona&#263; w ka&#380;dym czasie zmiany ustawie&#324; dotycz&#261;cych plik&oacute;w cookies. Ustawienia te mog&#261; zosta&#263; zmienione w szczeg&oacute;lno&#347;ci w taki spos&oacute;b, aby blokowa&#263; automatyczn&#261; obs&#322;ug&#281; plik&oacute;w cookies w ustawieniach przegl&#261;darki internetowej b&#261;d&#378; informowa&#263; o ich ka&#380;dorazowym zamieszczeniu w urz&#261;dzeniu U&#380;ytkownika. Szczeg&oacute;&#322;owe informacje o mo&#380;liwo&#347;ci i sposobach obs&#322;ugi plik&oacute;w cookies dost&#281;pne s&#261; w ustawieniach oprogramowania (przegl&#261;darki internetowej).</span>
              </li>
              <li><span>Operator Strony informuje, &#380;e ograniczenia stosowania plik&oacute;w cookies mog&#261; wp&#322;yn&#261;&#263; na niekt&oacute;re funkcjonalno&#347;ci dost&#281;pne na Stronie.</span>
              </li>
              <li><span>Wi&#281;cej informacji na temat plik&oacute;w cookies dost&#281;pnych jest w sekcji &bdquo;Pomoc&rdquo; w menu przegl&#261;darki internetowej.</span>
              </li>
            </ol>
          </RichTextWrapper>
        </SectionRow>
      </SectionWrapper>
    </Layout>
  );
};

const Wrapper = styled.div.attrs({ className: "bg-primary" })``;

const Headline = styled.h1.attrs({ className: "font-bold text-white text-6xl mb-8 md:text-8xl" })``;

const RichTextWrapper = styled.div.attrs({ className: "text-xl text-justify select-none" })`
  h2, h3 {
    font-weight: bold;
    text-align: center;
    margin: 3rem 0;
    font-size: 1.5rem;
  }

  ol {
    list-style-type: decimal;
    margin-left: 3rem;

    li {
      margin-bottom: 0.5rem;
    }
    
    ol {
      list-style-type: lower-alpha;
      margin-top: 0.5rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }
`;

export default StatutPage;